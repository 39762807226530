















import { Component, Vue } from 'vue-property-decorator'
import ContentViewer from '@/components/content/ContentViewer.vue'
import ContentFactory from '@/factories/content'
import FetchContentMixin from '@/mixins/content'
import ContentAPI from '@/api/requests/content'
import { ContentAdapter } from '@/api/adapters/content'
import { contentStatus, contentType } from '@/config/content/contentStatus'
import _ from 'lodash'

@Component({
  components: { ContentViewer },
  mixins: [FetchContentMixin]
})
export default class NewsViewer extends Vue {
  private pageId: number | string = this.$route.params.id
  private newsObject: any = ContentFactory.emptyNewsObject()
  private showSpinner = true

  private getSingleContent!: Function

  private async onChangeStatus() {
    this.showSpinner = true
    const contentId = Number(this.newsObject.id)
    this.newsObject.status =
      this.newsObject.status === contentStatus.draft
        ? contentStatus.publicated
        : contentStatus.draft

    const newsObjectCopy = _.cloneDeep(this.newsObject)
    newsObjectCopy.eventDate = ContentAdapter.changeNewsDateFormatToServer(
      newsObjectCopy.eventDate
    )

    const response = await ContentAPI.editContent(
      contentId,
      newsObjectCopy,
      newsObjectCopy.status
    )
    this.newsObject.status = response.data.content.status
    this.showSpinner = false
  }

  private async initializeComponent() {
    if (this.pageId === 'new') {
      this.newsObject = ContentFactory.emptyNewsObject()
    } else {
      this.pageId = parseInt(this.$route.params?.id)

      const newsObjectResponse = await this.getSingleContent(
        contentType.news,
        this.pageId
      )
      newsObjectResponse.avatarUrl = ContentAdapter.adaptAvatarUrl(
        newsObjectResponse.avatarUrl
      )

      newsObjectResponse.eventDate = ContentAdapter.changeDateFormat(
        newsObjectResponse.type,
        newsObjectResponse.eventDate
      )

      this.newsObject = newsObjectResponse
    }
    this.showSpinner = false
  }

  private async created() {
    await this.initializeComponent()
  }
}
